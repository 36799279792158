<template>
  <div id="slates" class="flex-v">
    <h1>Slates</h1>
    <DatePicker :value="$store.state.selectedDate" @input="changeDate" />
    <div v-if="loading" class="loading"></div>
    <p v-else-if="!slates.length">No slates this week</p>
    <ul v-else @click="changeSlate">
      <li v-for="s in slates" :key="s.identifier">
        <header>
          <h2>{{ s.label || s.type || `(No Label)` }}</h2>
          <h4>{{ s.contests[0].type }}</h4>
        </header>
        <p>{{ s.games.length }} game{{ s.games.length == 1 ? '' : 's' }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { getClickedListElement } from '../util/util.js';
import DatePicker from '../components/DatePicker.vue';
export default {
  name: 'Slates',
  components: { DatePicker },
  data() {
    return { loading: false };
  },
  computed: {
    slates() {
      return this.$store.state.slates;
    },
    season() {
      return this.$store.getters.season;
    },
    week() {
      return this.$store.state.seasonWeek;
    }
  },
  async created() {
    await this.$store.dispatch(`changeDate`);
    await this.getSlates();
  },
  methods: {
    async getSlates(options = undefined) {
      this.loading = true;
      await this.$store.dispatch(`getFantasyGames`, options);
      this.loading = false;
    },
    async changeSlate(e) {
      const i = getClickedListElement(e);
      this.$store.commit(`selectSlate`, i);
      this.$router.push(`/slates/${this.slates[i].identifier}`);
    },
    async changeDate(e) {
      await this.$store.dispatch(`changeDate`, e);
      await this.getSlates({ fromCache: false });
    }
  }
};
</script>

<style>
#slates {
  height: 100%;
}

#slates > h3 {
  text-align: left;
}

#slates > h3 input {
  width: 3rem;
}

#slates > h3 input:not(:focus) {
  padding: 0;
  border: 0;
  background: none;
  color: #c4c4c4;
  font-size: 1.17rem;
  font-weight: 700;
}

#slates > h3 input[type='number']::-webkit-inner-spin-button:not(:focus),
#slates > h3 input[type='number']::-webkit-outer-spin-button:not(:focus) {
  -webkit-appearance: none;
  margin: 0;
}
#slates > h3 input[type='number']:not(:focus) {
  -moz-appearance: textfield;
}

#slates ul {
  margin: 1rem 0;
  overflow: auto;
}
#slates ul > li {
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  background: var(--clr-dk-accent);
  cursor: pointer;
}

#slates ul > li p {
  padding-left: 1rem;
  margin-left: auto;
}

#slates ul header {
  text-align: left;
}

#slates ul header > * {
  margin: 0.5rem 0;
}

#slates ul header h4 {
  color: var(--clr-secondary);
}
</style>
