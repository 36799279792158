<template>
  <details class="datepicker">
    <summary>{{ text }}</summary>
    <button @click="close"></button>
    <div>
      <div>
        <button class="backArrow" @click="moveMonth(-1)"></button>
        <p>
          <b>{{ months[month] }}</b> {{ year }}
        </p>
        <button class="backArrow invert" @click="moveMonth(1)"></button>
      </div>
      <div @click="selectDay">
        <span>Mon</span>
        <span>Tue</span>
        <span>Wed</span>
        <span>Thu</span>
        <span>Fri</span>
        <span>Sat</span>
        <span>Sun</span>
        <!-- eslint-disable-next-line vue/require-v-for-key -->
        <span v-for="d in days" :class="{ active: d.active, today: d.today }">
          {{ d.day }}
        </span>
      </div>
    </div>
  </details>
</template>

<script>
export default {
  name: 'DatePicker',
  props: {
    value: {
      type: Date,
      default() {
        return new Date();
      }
    }
  },
  data() {
    return {
      year: 2020,
      month: 8,
      months: [
        `Jan`,
        `Feb`,
        `Mar`,
        `Apr`,
        `May`,
        `Jun`,
        `Jul`,
        `Aug`,
        `Sep`,
        `Oct`,
        `Nov`,
        `Dec`
      ],
      currentMonth: 1,
      currentDay: 1,
      selectedDay: 1
    };
  },
  computed: {
    days() {
      return this.getMonth(this.month, this.year);
    },
    text() {
      return `${new Intl.DateTimeFormat('en-US', {
        weekday: `short`,
        month: `numeric`,
        day: `numeric`,
        year: `numeric`
      }).format(new Date(this.year, this.month, this.selectedDay))}`;
    }
  },
  created() {
    this.setDate(this.value);
  },
  methods: {
    setDate(d) {
      this.year = d.getFullYear();
      this.month = this.currentMonth = d.getMonth();
      this.selectedDay = this.currentDay = d.getDate();
    },
    /**move toward the bounds, first or last month of year, and roll over when reached */
    moveMonth(dir) {
      if (!dir) return;

      const bnd = dir > 0 ? 11 : 0;
      if (this.month != bnd) {
        this.month += dir;
      } else {
        // 11 is total months, total - first/last month gives the opposite
        this.month = 11 - bnd;
        this.year += dir;
      }
    },
    getMonth(month, year) {
      // 42 fits in a 7x6 grid
      const days = new Array(42);
      let arrIndex = 0;
      // get 1st day of month, -1 for Sunday offset (at end of week instead of beginning)
      const startDay = new Date(year, month).getDay() - 1;

      if (startDay > 0) {
        // month starts after week starts
        let yr = year,
          m = month;

        if (month == 0) {
          yr--;
          m = 11;
        }

        const prevMonthLastDay = new Date(yr, m, 0).getDate();

        // fill beginning of week with previous month's days
        for (let i = startDay; i > 0; --i) {
          days[arrIndex] = { day: prevMonthLastDay - i };
          arrIndex++;
        }
      }

      // 0 is last day of previous month
      const endDay = new Date(year, month + 1, 0).getDate();

      // current month's days
      for (let i = 1; i <= endDay; ++i) {
        days[arrIndex] = { day: i, active: true };

        if (i == this.currentDay && this.month == this.currentMonth) {
          days[arrIndex].today = true;
        }

        arrIndex++;
      }

      // next month's days
      for (let i = 1; arrIndex < days.length; ++i) {
        days[arrIndex] = { day: i };
        arrIndex++;
      }

      return days;
    },
    selectDay(e) {
      const sibs = e.target.parentElement.children;
      for (let i = 0; i < sibs.length; ++i) {
        if (sibs[i] === e.target && e.target.classList.contains('active')) {
          this.$el.open = false;
          this.selectedDay = +e.target.innerText;

          this.$emit(
            'input',
            new Date(this.year, this.month, this.selectedDay)
          );
        }
      }
    },
    close(e) {
      this.setDate(this.value);
      e.target.closest(`details`).open = false;
    }
  }
};
</script>

<style>
.datepicker {
  position: relative;
  min-width: 230px;
  max-width: 400px;
}

.datepicker > summary {
  list-style: none;
  color: var(--clr-lt-accent);
  text-align: left;
  padding: 1rem 0 0.5rem;
}

.datepicker > summary::-webkit-details-marker {
  display: none;
}

.datepicker > div {
  border-radius: 10px;
  background: #414441;
  padding: 0.5rem;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.18),
    0px 1px 2px 0px rgba(0, 0, 0, 0.5);
  /* display: none; */
}

.datepicker > button {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  border: none;
  background: none;
}

.datepicker > div > div {
  min-width: 2rem;
  min-height: 1rem;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.datepicker > div > div > p b {
  font-size: 1.5rem;
  margin-right: 1rem;
}

.datepicker > div > div button {
  cursor: pointer;
}

.datepicker > div > div > span {
  margin: auto;
  padding: 0.2rem;
}

.datepicker span.today {
  border: 2px solid var(--clr-dk-accent);
  border-radius: 4px;
}

.datepicker > div > div:nth-of-type(1) {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 0.5rem;
}

.datepicker > div > div > span:not(.active):nth-of-type(n + 8) {
  color: #61625f;
  cursor: default;
}

.datepicker > div > div > span.active {
  color: var(--clr-primary);
  cursor: pointer;
}
</style>
